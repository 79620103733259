<template>
    <div class="filter-wrapper">
        <div style="display: flex;align-items: center;height: 36px;">
            <div>
            <el-dropdown @command="handleChangeCourseType">
                <span class="el-dropdown-link">
                    {{ queryList.courseType | courseType}}<i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="2">教师课表</el-dropdown-item>
                    <el-dropdown-item command="3">年级课表</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="line"></div>
        <div class="select-class" v-show="queryList.courseType == '2'">
            <span class="label"></span>
                <select-tree
                    placeholder="选择教师"
                    v-if="queryList.courseType == '2'"
                    v-model="queryList.classTeacherId"
                    style="width: 200px;"
                    :data="teacherList"
                    :props="{
                        value: 'id',
                        label: (data) => data.name,
                        children: 'children'
                    }"
                    @handlerSupervisingTeacherChange="handlerTeacherChange"
                >
                </select-tree>
        </div>
        <div class="select-class" v-show="queryList.courseType == '3'">
            <span class="label"></span>
                <ElSelectTree
                    placeholder="选择年级"
                    v-model="queryList.scheduleGradeId"
                    :data="gradeList"
                    :props="{
                        value: 'id',
                        label: (data) => data.name,
                        children: 'child'
                    }"
                    filterable
                    @change="handlerGradeChange"
            />
        </div>
        <div :class="['select-week', { current: queryList.courseType == '3'}]">
            <span class="label"></span>
            <el-date-picker style="width: 200px;"
                    v-model="queryList.selectDay"
                    type="date"
                    :clearable="false"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
        </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import ElSelectTree from 'el-select-tree';
    Vue.use(ElSelectTree);

    import virtualList from 'vue-virtual-scroll-list'
    import SelectTree from './Sub/SelectTree'
    import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";

    import CourseSchedule from '@/filters/CourseSchedule.js'
    export default {
        name: "TeachingResearchCourseScheduleTempFilterWrapper",
        filters: CourseSchedule,
        components: {
            ElSelectTree,
            'virtual-list': virtualList,
            SelectTree,
            ExpandFilter,

        },
        props: {
            queryList: Object,
            formData:Object,
            statusList: Array,
            schedulingClassesList: Array,
            teacherList: Array,
            gradeList: Array
        },
        data () {
            return {
            }
        },
        mounted(){
        },
        methods: {
            handleChangeCourseType (e) {
                this.$emit('handleChangeCourseType', e)
            },
            handlerScheduleClassIdChange () {
                this.$emit('handlerScheduleClassIdChange')
            },
            handlerTeacherChange () {
                this.$emit('handlerTeacherChange')
            },
            handlerGradeChange () {
                this.$emit('handlerGradeChange')
            },
            // 选中年级班级
        changeCascader(data) {
            console.log("选择的年纪班级",data)
            this.$emit('changeCascader',data)
            data.ref.dropDownVisible = false;
        },
     }
    }
</script>

<style lang="scss" scoped>
    .filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 36px;
        .el-dropdown-link {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #878787;
            .el-icon-caret-bottom {
                color: #878787;
            }
        }
        .line {
            width: 1px;
            height: 24px;
            margin: 0 24px;
            background-color: #DBDBDB;

        }
        .select-class {
            display: flex;
            align-items: center;
            .label {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #878787;
                margin-right: 10px;
            }
        }
        .select-week {
            display: flex;
            align-items: center;
            .label {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #878787;
                margin-right: 10px;
            }
        }
        .select-week.current {
            margin-right: auto;
        }
        .select-status {
            display: flex;
            width: 68px;
            height: 32px;
            align-items: center;
            box-sizing: border-box;
            padding: 3px;
            background: #F2F2F2;
            border-radius: 6px;
            margin-left: 24px;
            margin-right: auto;
            .item {
                width: 30px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                border-radius: 2px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #A6A6A6;
                cursor: pointer;
            }
            .item.current {
                background-color: #fff;
                color: #595959;
            }
        }

    }

</style>
