<template>
    <div>
        <div class="course-schedule-view" id="pdfPreviewDom1">
            <CourseTableTitle :classAndTeacher="classAndTeacher" :type="type" class="course-table-title"></CourseTableTitle>
            <div v-if="loding" class="course-schedule-view-content">
                <CourseList
                :courseList="courseList"
                :queryList="queryList"
                :isTourClass="isTourClass"
                :type="type"
                />
                <WeekList
                v-if="queryList.dayWeek === 'week' && !getDataLoading"
                :advanceTime="advanceTime"
                :weekList="weekList"
                :showDownloadPDFType="showDownloadPDFType"
                :isTourClass="isTourClass"
                :courseList="courseList"
                :type="type"
                />
            </div>
        </div>
    </div>

</template>
<script>
import CourseTableTitle from './CourseScheduleView/CourseTableTitle.vue';
import CourseList from './CourseScheduleView/CourseList.vue';
import WeekList from './CourseScheduleView/WeekList.vue';

export default {
    name: "CourseScheduleView",
    components: {
        CourseList,
        WeekList,
        CourseTableTitle
    },
    props: {
        classAndTeacher:Object,
        queryList: Object,
        courseList: Array,
        weekList: Array,
        isTourClass: String,
        type: String,
        showDownloadPDFType: Boolean,
        getDataLoading: Boolean,
        loding:{
            default:true,
        },
        advanceTime: Number
    },
}
</script>

<style lang="scss" scoped>
    .course-schedule-view {
        border: 1px solid #61AFFF;
        margin-top: 24px;
        overflow-x: scroll;

        .course-table-title{
            height: 48px;
            width: 100%;
        }
        .course-schedule-view-content{
            height: calc(100vh - 300px);

            overflow: auto;
            display: flex;
            background-color: #FAFAFA;
        }
        border-radius: 8px;
    }
</style>
